import React from "react";
import "./services.css";

const Services = () => {
  return (
    <div className="pages_container">
      <div className="services_header_container">
        <h1 className="services_header">Servicii</h1>
      </div>
      <div className="services_card_container">
        <div className="card">
          <div className="services_picture_container">
            <img
              src="images/excavari.png"
              alt="cardPic"
              className="card_picture"
            />
          </div>
          <p>
            Oferim excavatii generale si sapaturi fundatii blocuri, case,
            sapaturi pentru canalizare, apa si lumina, excavari piscine, santuri
            pentru conducte de gaze, gropi pentru beciuri. Executia sapaturilor
            se face mecanizat utilizand excavatoare cu capacitati specifice
            lucrarilor.
          </p>
        </div>
        <div className="card">
          <div className="services_picture_container">
            <img src="images/case.png" alt="cardPic" className="card_picture" />
          </div>
          <p>
            Executam constructii de case la rosu, case la gri si la case cheie,
            renovari. Amenajari interioare si exterioare. Daca aveti de gand sa
            construiti o casa nu ezitati sa apelati la echipa nostra de
            specialisti care vine in sprijinul dumneavostra cu servicii de
            calitate la preturi reduse.​
          </p>
        </div>
        <div className="card">
          <div className="services_picture_container">
            <img
              src="images/demolari.png"
              alt="cardPic"
              className="card_picture"
            />
          </div>
          <p>
            Executam lucrari de demolari constructii civile si industriale,
            demolari de fabrici, hale, de silozuri si turnuri, de cosuri de fum,
            demolam elemente de infrastructura sau de suprastructura, sau putem
            demola elemente din interiorul anumitor structuri.
          </p>
        </div>
        <div className="card">
          <div className="services_picture_container">
            <img
              src="images/teren.png"
              alt="cardPic"
              className="card_picture"
            />
          </div>
          <p>
            Efectuam lucrări de pregätire a terenului in vederea realizării de
            constructii, urbanizari, realizarea infrastructurii de
            drumuri,nivelare,compactare,umplutură cu pământ nevegetal, asternere
            materiale de constructii pentru stabilizare (piatra spartã,
            balast,nisip). ​
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
