import "@fortawesome/fontawesome-free/css/all.min.css";
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import Informations from './pages/Informations';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Contact from './pages/Contact';
import Services from "./pages/Services";


export const Pages = {
  Home: "/",
  Services: "/servicii",
  Informations: "/informatii",
  AboutUs: "/despre-noi",
  Contact: "/contact"
};

const App = () => {
  return (
    <>
      <Header />
        <Routes>
          <Route
            path={Pages.Home}
            element={<Home />}
          ></Route>
          <Route
            path={Pages.Services}
            element={<Services />}
          ></Route>
          <Route
            path={Pages.Informations}
            element={<Informations />}
          ></Route>
          <Route
            path={Pages.AboutUs}
            element={<AboutUs />}
          ></Route>
          <Route
            path={Pages.Contact}
            element={<Contact />}
          ></Route>
          <Route path="*" element={<Navigate to={Pages.Home} />}></Route>
        </Routes>
        <Footer />
    </>
  );
};

export default App


