import React from "react";
import DragPictureEffect from "../components/dragPictureEffect/DragPictureEffect";
import "./home.css";

const pictureUrls = [
  "images/landing3.png",
  "images/landing2.png",
  "images/landing1.png",
  "images/landing4.png",

  // Add more picture URLs as needed
];

const Home = () => {
  return (
    <div className="pages_container">
      <div className="home_header_container">
        <h1 className="home_header">Home</h1>
      </div>
      <div className="home_container">
        <DragPictureEffect pictures={pictureUrls} />
      </div>
    </div>
  );
};

export default Home;
