import React from "react";
import "./aboutUs.css";

const AboutUs = () => {
  return (
    <div className="pages_container">
          <h1 className="aboutus_header">Despre Noi</h1>
          <div className="informations_container">
          <img
            src="images/desprenoi.png"
            alt="DespreNoi"
            className="informations_ilustration"
          />
          <div className="about_text">
            <p>
              RODILA BUILDCON SRL, este o companie care asigura solutii complete
              pentru o gama larga de activitati in domeniul constructiilor,
              consultanta si executie, amenajari interioare si exterioare.​{" "}
            </p>
            <p>
              Până în momentul de fată am colaborat cu foarte multe companii din
              România pentru amenajri si constructii in diferite proiecte.​{" "}
            </p>
            <p>
              Ne putem lauda cu echipa noastrã de profesionisti, cu seriozitate
              si promptitudinea de care dau dovada. Într-un domeniu in care
              calitatea isi spune punctul de vedere.​
            </p>
            <p>
              Raspundem cu profesionalism la cerintele tot mai diversificate ale
              clientilor nostri si suntem în măsura sa gasim solutii
              personalizate în functie de orice dorintã pâna la executia la
              cheie a obiectivelor.​
            </p>
            <p>
              Societatea se află intr-un permanent proces de perfectionare,
              dezvoltând în acelasi timp relatii de colaborare corecte si
              trainice cu partenerii sãi si cu furnizorii si producatori de
              materiale de constructii.​
            </p>
            <p>
              Obiectivul de baza al companiei noastre este de a oferii servicii
              de înalta calitate cu cerintele clientului.​
            </p>
          </div>
      </div>
    </div>
  );
};

export default AboutUs;
