import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.css";
import { Pages } from "../../App";

const Header = () => {
 
  const location = useLocation();

  const isOnCurrentPage = (currentPage: string) =>
    location && location.pathname === currentPage;

  return (
    <div className="header">
      <div className="header_container">
        <div className="logo">
          <Link to="index.html">
            <img src="images/site_logo2.png" alt="Rodila"></img>
          </Link>
        </div>
        <div className="pages">
          <ul>
            <li>
              <Link
                to={Pages.Home}
                className={isOnCurrentPage(Pages.Home) ? "active" : ""}>
                <span>Acasa</span>
              </Link>
            </li>
            <li>
              <Link
                to={Pages.Services}
                className={isOnCurrentPage(Pages.Services) ? "active" : ""}>
                <span>Servicii</span>
              </Link>
            </li>
            <li>
              <Link
                to={Pages.Informations}
                className={isOnCurrentPage(Pages.Informations) ? "active" : ""}>
                <span>Informatii</span>
              </Link>
            </li>
            <li>
              <Link
                to={Pages.AboutUs}
                className={isOnCurrentPage(Pages.AboutUs) ? "active" : ""}>
                <span>Despre noi</span>
              </Link>
            </li>
            <li>
              <Link
                to={Pages.Contact}
                className={isOnCurrentPage(Pages.Contact) ? "active" : ""}>
                <span>Contact</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
