import React from "react";
import "./informations.css";

const Informations = () => {
  return (
    <div className="pages_container">
      <div className="informations_text">
        <h1 className="informations_header">Informatii</h1>
        <div className="informations_container">
          <img
            src="images/informatii.png"
            alt="Informatii"
            className="informations_ilustration"
          />
          <div className="informations_text_container">
            <p>
              Avem un program foarte flexibil deoarece echipa noastra este
              prezenta in santier de LUNI – VINERI in intervalul orar 08:00 –
              17:00 DAR (oriunde exista un DAR).​
            </p>
            <p>
              Daca nevoia de finalizare a unei etape necesita prezenta noastra
              vom fi acolo pana la finalizare.​
            </p>
            <p>
              SAMBATA – de obicei LIBER, DAR (acelasi DAR), daca clientii nostri
              doresc putem fi acolo pentru ei si in aceasta zi.​ DUMINICA – este
              LIBERA pentru toata echipa (de la mic la mare)​.
            </p>
            <p>
              Pentru preturi si pentru disponibilitate va rugam sa ne contactati
              la una din metodele mentionate in rubrica dedicata.​
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Informations;
