import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer footer-mobile">
      <div className="footer_container">
        <div className="excavator_icon_container">
          <Link to="/">
            <img
              src="images/excavator.png"
              alt="Excavator"
              className="excavator_icon"
            />
          </Link>
        </div>
        <div className="copyrights">
          Rodila Buildcon ©{new Date().getFullYear()}
        </div>
        <div className="social-icons">
          <Link
            to="https://www.facebook.com/profile.php?id=61552686815257"
            target="_blank"
            aria-label="Facebook">
            <FontAwesomeIcon icon={faFacebook} size="2x" color="black" />
          </Link>
          <Link to="https://www.instagram.com/rodilabuildcon/" target="_blank" aria-label="Instagram">
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              color="black"
            />
          </Link>
          <Link to="/" target="_blank" aria-label="LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} size="2x" color="black" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
