import React, { useState } from "react";
import "./contact.css";

const salesEmail = "rodilabuildcon@gmail.com";
const phoneNumber = "0757 483 669";

const Contact = () => {
  const [emailHover, setEmailHover] = useState(false);
  const [phoneHover, setPhoneHover] = useState(false);
  const [locationHover, setLocationHover] = useState(false);
  const [clockHover, setClockHover] = useState(false);
  return (
    <div className="pages_container">
      <div className="text_container">
        <h1 className="contact_header">Contact</h1>
        <div className="about_ilustration_container">
          <div className="contact_img_container">
            <img
              src="images/contact.png"
              alt="contact"
              className="contact_img"
            />
          </div>
          <div>
            <p>
              Daca aveti orice nelamuriri referitoare la gama noastra de
              servicii sau doriti sa ne transmiteti un mesaj, suntem aici pentru
              a va ajuta. Echipa noastra este mereu pregatita sa va ofere
              informatiile de care aveti nevoie si sa raspunda intrebarilor dvs.
              in mod prompt si profesionist. Contactati-ne cu incredere, iar noi
              va vom oferi sprijinul de care aveti nevoie, asigurandu-ne ca veti
              primi un raspuns in cel mai scurt timp posibil. Suntem dedicati sa
              va oferim servicii de cea mai inalta calitate si sa ne asiguram ca
              experienta dvs. cu noi este una placuta si eficienta.
              Contactati-ne si va vom raspunde in cel mai scurt timp posibil.
            </p>
            <p>Va multumim pentru ca ne-ati ales!​</p>
          </div>
        </div>
      </div>
      <div className="info-container">
        <div
          className="info-item"
          onMouseEnter={() => setEmailHover(true)}
          onMouseLeave={() => setEmailHover(false)}>
          <a href={`mailto:${salesEmail}`} className="text-wrapper">
            <i
              className={`fa-solid fa-envelope fa-lg icon-color ${
                emailHover ? "fa-beat" : ""
              }`}
            />
            <span className="info-text">{salesEmail}</span>
          </a>
        </div>
        <div
          className="info-item"
          onMouseEnter={() => setPhoneHover(true)}
          onMouseLeave={() => setPhoneHover(false)}>
          <div className="text-wrapper">
            <i
              className={`fa-solid fa-phone fa-lg icon-color ${
                phoneHover ? "fa-beat" : ""
              }`}
            />
            <span className="info-text">{phoneNumber}</span>
          </div>
        </div>
        <div
          className="info-item"
          onMouseEnter={() => setLocationHover(true)}
          onMouseLeave={() => setLocationHover(false)}>
          <div className="text-wrapper">
            <i
              className={`fa-solid fa-map-location-dot fa-lg icon-color ${
                locationHover ? "fa-beat" : ""
              }`}
            />
            <span className="info-text">
              Str. Principala nr.208​ Sat. Breaza​ Jud. Bistrita-Nasaud​
            </span>
          </div>
        </div>
        <div
          className="info-item"
          onMouseEnter={() => setClockHover(true)}
          onMouseLeave={() => setClockHover(false)}>
          <div className="text-wrapper">
            <i
              className={`fa-solid fa-clock fa-lg icon-color ${
                clockHover ? "fa-beat" : ""
              }`}
            />
            <span className="info-text">
              Luni – Vineri - 08:00 - 17:00​ Sambata – program regim special
              (contactati-ne pentru detalii) ​
            </span>
          </div>
        </div>
      </div>
      <div className="map_container">
        <iframe
          title="locationMap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3214.9488684537314!2d24.056935482062848!3d47.34482693009027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4749dbdbea155187%3A0x2a9b2852ced75064!2sBreaza!5e0!3m2!1sen!2sro!4v1702317677623!5m2!1sen!2sro"
          width="100%"
          height="150vh"
          loading="lazy"
          style={{ border: "0", borderRadius: "10px" }}
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

export default Contact;
